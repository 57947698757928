
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiShopDetail, apiSetPaySettle } from "@/api/shop";
@Component
export default class PaySettle extends Vue {
    /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    // 添加商城表单数据
    form: any = {
        appid:"",
        api_key_live:"",
        api_key_test:"",
        rsa_public_key:"",
        rsa_private_key:"",
        settle_service_charge:0,
        settle_user_mobile:''
    };

    // 表单校验
    rules = {
        appid: [
            { required: true, message: "请输入汇付应用ID", trigger: "blur" },
        ],
        api_key_live: [
            { required: true, message: "请输入正式APPKEY", trigger: "blur" },
        ],
        api_key_test: [
            { required: true, message: "请输入测试APPKEY", trigger: "blur" },
        ],
        rsa_public_key: [
            { required: true, message: "请输入汇付公钥", trigger: "blur" },
        ],
        rsa_private_key: [
            { required: true, message: "请输入汇付私钥", trigger: "blur" },
        ],
        settle_service_charge: [
            { required: true, message: "请输入结算手续费", trigger: "blur" },
        ],
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            this.handleShopEdit();
        });
    }

    // 编辑支付结算
    async handleShopEdit(): Promise<void> {
        await apiSetPaySettle({ ...this.form, id: this.identity });
        setTimeout(() => this.$router.go(-1), 500);
    }

    // 获取详情
    async getShopDetailFunc(): Promise<void> {
        const res: any = await apiShopDetail({
            id: this.identity as number
        })
        this.form.appid = res.appid;
        this.form.api_key_live = res.api_key_live;
        this.form.api_key_test = res.api_key_test;
        this.form.rsa_public_key = res.rsa_public_key;
        this.form.rsa_private_key = res.rsa_private_key;
        this.form.settle_service_charge = res.settle_service_charge;
        this.form.settle_user_mobile = res.settle_user_mobile;
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;
        // 编辑模式：初始化数据
        this.identity = +query.id;
        if (+query.id) {
            this.getShopDetailFunc();
        }
    }
    /** E Life Cycle **/
}
